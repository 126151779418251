module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"remarkPlugins":[null],"rehypePlugins":[null],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-prismjs","id":"5023d14b-37aa-5851-92fb-c14b1e804e35","name":"gatsby-remark-prismjs","version":"6.16.0","modulePath":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"noInlineHighlight":true},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-images","id":"8031466d-59ed-5d13-9010-c33c8fa821d9","name":"gatsby-remark-images","version":"6.16.0","modulePath":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"wrapperStyle":"display:block;margin-top:1em;margin-bottom:1em;"},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.16.0","modulePath":"/home/runner/work/pascalbugnion.net/pascalbugnion.net/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/pascalbugnion.net/pascalbugnion.net","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"wrapperStyle":"display:block;margin-top:1em;margin-bottom:1em;"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-74417633-1"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
