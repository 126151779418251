exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-index-tsx": () => import("./../../../src/pages/code/index.tsx" /* webpackChunkName: "component---src-pages-code-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-index-tsx": () => import("./../../../src/pages/notes/index.tsx" /* webpackChunkName: "component---src-pages-notes-index-tsx" */),
  "component---src-pages-notes-tree-icon-tsx": () => import("./../../../src/pages/notes/TreeIcon.tsx" /* webpackChunkName: "component---src-pages-notes-tree-icon-tsx" */),
  "component---src-templates-notes-index-tsx": () => import("./../../../src/templates/notes/index.tsx" /* webpackChunkName: "component---src-templates-notes-index-tsx" */)
}

